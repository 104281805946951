<template>
  <div class="flex items-center" @mouseleave="stars = 0">
    <button
      v-for="star in 5"
      :key="`star-${star}`"
      :disabled="disabled"
      class="w-10 h-10 mx-px transition-colors duration-200"
      :class="disabled ? 'cursor-not-allowed' : 'hover:text-yellow-400'"
      @click="emit('update:modelValue', star)"
      @mouseenter="!disabled ? (stars = star) : null"
    >
      <v-icon
        icon="fa: fa-solid fa-star"
        :size="size"
        :class="filled(star) ? 'text-rate' : colorNotFilled"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: number
    length: number
    size?: number
    disabled?: boolean
    colorNotFilled?: string
  }>(),
  {
    colorNotFilled: 'text-text_alt_color',
    modelValue: 0,
    disabled: false,
    size: 25,
    length: 5
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: number): void
}>()

const stars = ref(0)

const filled = (star: number) => star <= props.modelValue || star <= stars.value
</script>
